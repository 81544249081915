import React, { useState } from 'react';

function DebugPage() {
  const [amount, setAmount] = useState('');
  const [loading, setLoading] = useState(false);

  const generateMerchantOrderNo = () => {
    const timestamp = Date.now(); // Get the current timestamp in milliseconds
    const randomNumber = Math.floor(1000 + Math.random() * 9000); // Generate a 4-digit random number
    return `${timestamp}${randomNumber}`; // Concatenate timestamp and random number
  };

  const handlePayment = async () => {
    setLoading(true);
    try {
      const h5_Pay_v2_url = 'https://tomatopayment.com/api/payments/h5_pay_v2';
      //Test push notify url
      const notifyUrl = 'https://organic-magnetic-bat.ngrok-free.app/notify.php';

      const merchantOrderNo = generateMerchantOrderNo(); // Generate merchantOrderNo

      const response = await fetch(h5_Pay_v2_url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'apiKey': 'kM6sN1L2J9Xp3RzA4W7eV0yTgQ8oBfC',
        },
        body: JSON.stringify({
          merchantId: '2',
          orderAmount: parseInt(amount, 10),
          description: 'test description',
          merchantOrderNo: merchantOrderNo,
          notifyUrl: notifyUrl,
        }),
      });

      const result = await response.json();

      if (result.code === 200) {
        window.location.href = result.data.mweb_url;
      } else {
        alert('Payment failed: ' + result.message);
      }
    } catch (error) {
      console.error('Error:', error);
      alert('Payment request failed.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={styles.container}>
      <h2 style={styles.heading}>h5_pay_v2 Demo</h2>
      <input
        type="number"
        value={amount}
        onChange={(e) => setAmount(e.target.value)}
        placeholder="Enter amount"
        style={styles.input}
      />
      <button onClick={handlePayment} disabled={loading} style={styles.button}>
        {loading ? 'Processing...' : 'Confirm Payment'}
      </button>
    </div>
  );
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    padding: '20px',
    boxSizing: 'border-box',
  },
  heading: {
    fontSize: '24px',
    marginBottom: '20px',
    textAlign: 'center',
  },
  input: {
    width: '100%',
    maxWidth: '400px',
    padding: '10px',
    fontSize: '16px',
    marginBottom: '20px',
    borderRadius: '5px',
    border: '1px solid #ccc',
    boxSizing: 'border-box',
  },
  button: {
    width: '100%',
    maxWidth: '400px',
    padding: '15px',
    fontSize: '18px',
    color: '#fff',
    backgroundColor: '#007bff',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    boxSizing: 'border-box',
  },
};

export default DebugPage;
